import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  Link as DocsiteLink,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const AvatarUser = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.AvatarUser;
    return <Component {...props} />;
  },
});

const Link = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Link;
    return <Component {...props} />;
  },
});

const linkSnippet = require('raw-loader!../../../data/snippets/link.example');
const wrapperSnippet = require('raw-loader!../../../data/snippets/link-wrapper.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Link"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-link--docs"
      />
      <CodeSnippet
        scope={{ React: React, Link: Link }}
        code={linkSnippet}
        platform="react"
        gitHubLink="link"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']} isRequired>
            <Text>The text to display for the link.</Text>
          </PropListItem>

          <ClassnamePartial componentName="link" />

          <PropListItem name="displayType" types={['string']}>
            <Text>
              When using the wrapper type, this will determine the display of
              the link. This has no effect if type is set to 'button'.
            </Text>
            <List type="unordered">
              <li>
                <code>block</code>
              </li>
              <li>
                <code>inline-block</code>
              </li>
            </List>
          </PropListItem>

          <EnvironmentPartial />

          <PropListItem name="isExternal" types={['boolean']}>
            <Text>
              Whether or not the link should be treated as an external link.
              Adds the <code>UiNewWindow</code> icon and opens in a new tab by
              default. This should always be used for external links for
              accessibility purposes.
            </Text>
          </PropListItem>

          <PropListItem name="type" types={['string']}>
            <Text>Determines the type of the link. </Text>
            <List type="unordered">
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>article</code>
              </li>
              <li>
                <code>implied</code>
              </li>
              <li>
                <code>button</code> (deprecated)
              </li>
              <li>
                <code>wrapper</code>
              </li>
            </List>
            <br />
            <CodeSnippet
              scope={{ React: React, Link: Link, AvatarUser: AvatarUser }}
              code={wrapperSnippet}
              platform="react"
            />
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial componentName="link" />

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from a regular HTML{' '}
              <DocsiteLink href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a">
                anchor
              </DocsiteLink>{' '}
              element.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
      <Section title="Notes">
        <List type="unordered">
          <li>
            A link wrapper may be used around a button for{' '}
            <DocsiteLink href="/patterns/empty-states">
              empty states
            </DocsiteLink>{' '}
            and other calls-to-action that take users elsewhere. Work with your
            designer to decide the best approach.{' '}
          </li>
        </List>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Link"
      />
    </PlatformTab>
  );
};

export default WebTab;
