import React from 'react';
import WebTab from './_code-web';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../components';
import pageHeroData from '../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="code" title="Link" designCode subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Link" />

      <PlatformTabs platforms={{ web: <WebTab /> }} />
    </PageWithSubNav>
  );
};

export default IndexPage;
